import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import CheckInService from '../../../api/CheckInService.js'
import Page from '../../../components/page.vue'
import NoData from '../../../components/NoData.vue'

export default{
	name:'EntryList',
	data(){
		return{
			pageIndex:1,
			pageSize:10,
			pageCount:0,
			mainList:[],//列表
			searchWord:'',//搜索
			curClassType:0,//当前分类
			HotElementList:[],//热门词条
			HotEntryList:[],//热门条目
		}
	},
	methods:{
		GetResList(){ //获取条目列表
			var that = this
			CheckInService.GetResList(that.pageIndex,that.pageSize,that.searchWord.trim(),that.$route.query.classId,that.$route.query.type).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.mainList = res.Result
					that.pageCount = res.TotalCount
				}
			})
		},
		pageChange(page){
			this.pageIndex = page
			this.GetResList()
			window.scrollTo(0,0)
		},
		GetHotElementList(){ //获取热门词条
			var that = this
			CheckInService.GetHotResList(0,5,that.$route.query.classId).then(res=>{
				if(res.Tag == 1){
					that.HotElementList = res.Result
				}
			})
		},
		GetHotEntryList(){ //获取热门条目
			var that = this
			CheckInService.GetHotResList(1,5,that.$route.query.classId).then(res=>{
				if(res.Tag == 1){
					that.HotEntryList = res.Result
				}
			})
		},
		toSearch(){ //搜索
			this.pageIndex = 1
			this.GetResList()
			if(this.searchWord.trim()){
				CheckInService.SearchRecord(this.searchWord.trim(),this.$UserID).then(res=>{
					console.log(res)
				})
			}
		},
		toEntryDetail(id){ //条目详情
			window.open(this.$WebUrl+'EntryDetail?id='+id)
		},
		toElementDetail(id){ //词条详情
			window.open(this.$WebUrl+'ElementDetail?id='+id)
		},
	},
	created(){
		this.searchWord = this.$route.query.keyWord?this.$route.query.keyWord:''
		if(this.$route.query.keyWord){
			CheckInService.SearchRecord(this.searchWord.trim(),this.$UserID).then(res=>{
				console.log(res)
			})
		}
		this.GetResList()
		this.GetHotElementList()
		this.GetHotEntryList()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'Page':Page,
		'NoData':NoData
	}
}